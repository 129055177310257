import React from 'react';
import classNames from 'classnames';
import { BadgeList } from '@wix/members-badge-lib';
import { RoleId } from '@wix/members-domain-ts';

import {
  BaseComponentProps,
  AdditionalActionId,
  DataHook,
  Orientation,
  Position,
} from '../../../../../types';
import {
  getCoverImageStyle,
  getCoverUrl,
} from '../../../../../services/cover-utils';
import { absorbArguments } from '../../../../../services/event-handler';
import { maybeOpenContactsWindow } from '../../../../../services/navigation';
import Avatar from '../../../../../common/components/Avatar';
import MoreButton from '../../../../../common/components/MoreButton';
import PopoverContent from '../../../../../common/components/MemberMoreButton/PopoverContent';
import Blocked from '../../../../../common/components/Blocked';
import RoleIcons from '../../../../../common/components/RoleIcons';
import Button from '../../../../../common/components/Button';
import FollowButton from '../../../../../common/components/FollowButton';
import LoaderOverlay from '../../../../../common/components/LoaderOverlay';
import styles from './MobileViewWidget.scss';

export class MobileViewWidget extends React.Component<BaseComponentProps> {
  render = () => {
    const { site, profilePage } = this.props;
    const { isCoverLoading } = profilePage;
    const { isSocial } = site;

    const className = classNames({
      [styles.nonSocial]: !isSocial,
      [styles.box]: true,
    });

    return (
      <div data-hook={DataHook.MobileWidget}>
        <div className={className}>
          {this.renderCover()}
          {isCoverLoading && this.renderLoader()}
          {this.shouldRenderMoreButton() && this.renderMoreButton()}
          {this.renderMember()}
        </div>
      </div>
    );
  };

  private readonly shouldRenderFF = () => {
    const { site, globalSettings, computed } = this.props;
    const { showFollowers } = globalSettings;
    const { inCommunity, showAsBlocked } = computed;
    const { isSocial } = site;

    return isSocial && showFollowers && inCommunity && !showAsBlocked;
  };

  private readonly shouldRenderMoreButton = () => {
    const { additionalActions, rolesActions } = this.props.computed;
    return rolesActions.length > 0 || additionalActions.length > 0;
  };

  private readonly handleRoleAction = (roleId: RoleId) => {
    if (roleId === RoleId.CONTACT_PAGE) {
      const { metaSiteId } = this.props;
      return maybeOpenContactsWindow(
        metaSiteId,
        this.props.member.uid,
        this.props.computed.isResponsiveEditor,
      );
    }

    this.props.handlers.executeRoleAction(roleId);
  };

  private readonly renderMoreButton = () => (
    <div className={styles.moreButtonContainer}>
      <div>
        <MoreButton
          position={Position.Top}
          alignment={Position.Right}
          dynamicPositioning={false}
          orientation={Orientation.Horizontal}
          iconContainerClass={styles.iconContainer}
          isRtlLocale={this.props.isRTL}
          isDisabled={this.props.profilePage.isProfilePreview}
          isMobile={true}
          t={this.props.t}
        >
          <PopoverContent
            additionalActions={this.props.computed.additionalActions}
            rolesActions={this.props.computed.rolesActions}
            isMobile={true}
            t={this.props.t}
            onAdditionalAction={this.props.handlers.executeAdditionalAction}
            onRoleAction={this.handleRoleAction}
          />
        </MoreButton>
      </div>
    </div>
  );

  private readonly renderBadges = () => {
    const { site, computed, handlers } = this.props;
    const { isSocial } = site;
    const { badgesSettings } = computed;
    const { navigateToViewedMember } = handlers;

    return (
      badgesSettings.badges.length > 0 && (
        <div className={styles.badgesContainer}>
          <BadgeList
            {...badgesSettings}
            onClickMore={isSocial ? navigateToViewedMember : undefined}
            onBadgeClick={isSocial ? navigateToViewedMember : undefined}
            useTextRemainder={false}
            maxRows={1}
            align={isSocial ? 'left' : 'center'}
            size="small"
          />
        </div>
      )
    );
  };

  private readonly renderButtons = () => {
    const { profilePage, computed } = this.props;
    const {
      canEdit,
      inCommunity,
      isInProfilePage,
      showAsBlocked,
      allowChat,
    } = computed;

    if (canEdit && !profilePage.isProfilePreview) {
      const { showEditProfileCTA, showViewPublicProfileCTA } = computed;

      return (
        <div className={styles.buttonContainer}>
          {inCommunity && showEditProfileCTA && this.renderEditButton()}
          {inCommunity &&
            showViewPublicProfileCTA &&
            this.renderViewPublicProfileButton()}
          {!inCommunity &&
            !showAsBlocked &&
            isInProfilePage &&
            this.renderJoinCommunityButton()}
        </div>
      );
    }

    return (
      <div className={styles.buttonContainer}>
        {inCommunity && !showAsBlocked && allowChat && this.renderChatButton()}
        {inCommunity && !showAsBlocked && this.renderFollowUnfollowButton()}
      </div>
    );
  };

  private readonly renderFollowers = () => {
    const { member, t, handlers, computed } = this.props;
    const { followersInstalled } = computed;
    const handleFollowersBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(true);
    });

    const handleFollowingBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(false);
    });

    return (
      <span className={styles.followingFollowers}>
        <button
          data-hook={DataHook.Followers}
          onClick={handleFollowersBoxClick}
          disabled={!followersInstalled}
        >
          {member.followerCount + ' ' + t('profile-widget.followers')}
          {/* TODO: Add different translation key for 1 follower when translations are available */}
        </button>
        <button
          data-hook={DataHook.Following}
          onClick={handleFollowingBoxClick}
          disabled={!followersInstalled}
        >
          {member.followingCount + ' ' + t('profile-widget.following')}
        </button>
      </span>
    );
  };

  private readonly renderFollowUnfollowButton = () => (
    <FollowButton
      dataHook={DataHook.FollowCTA}
      className={styles.button}
      followText={this.props.t('profile-widget.follow')}
      unfollowText={this.props.t('profile-widget.unfollow')}
      isFollowing={this.props.member.isSubscribed}
      isDisabled={this.props.profilePage.isProfilePreview}
      type="text"
      onClick={absorbArguments(this.props.handlers.followOrUnfollow)}
    />
  );

  private readonly renderChatButton = () => {
    const { profilePage, handlers } = this.props;
    const handleChatButtonClick = absorbArguments(handlers.openChat);

    return (
      <Button
        className={styles.button}
        isDisabled={profilePage.isProfilePreview}
        onClick={handleChatButtonClick}
      >
        {this.props.t('profile-widget.message')}
      </Button>
    );
  };

  private readonly renderEditButton = () => (
    <button
      data-hook={DataHook.EditProfileCTA}
      className={styles.button}
      onClick={absorbArguments(this.props.handlers.toggleIsEditingProfile)}
    >
      {this.props.t('profile-widget.edit')}
    </button>
  );

  private readonly renderViewPublicProfileButton = () => (
    <button
      data-hook={DataHook.ViewPubLicProfileCTA}
      className={styles.button}
      onClick={absorbArguments(this.props.handlers.togglePublicProfilePreview)}
    >
      {this.props.t('profile-widget.view-public-profile')}
    </button>
  );

  private readonly renderJoinCommunityButton = () => {
    const { handlers } = this.props;
    const handleJoinCommunity = () => {
      handlers.executeRoleAction(RoleId.JOIN_COMMUNITY);
    };

    return (
      <button
        data-hook={DataHook.JoinCommunityCTA}
        className={classNames(styles.button, styles.communityJoinButton)}
        onClick={handleJoinCommunity}
      >
        {this.props.t('MemberRoles.action_set.community')}
      </button>
    );
  };

  private readonly renderRoleIcons = () => (
    <RoleIcons
      containerClassName={styles.roleIcons}
      iconClassName={styles.roleIcon}
      roles={this.props.member.roles || []}
      rolesMap={this.props.rolesMap}
      t={this.props.t}
    />
  );

  private readonly renderNameAndRoleIcons = () => (
    <div className={styles.nameAndRoleIcons}>
      <span data-hook={DataHook.MemberName} className={styles.profileName}>
        {this.props.member.name}
      </span>
      {this.props.site.isSocial && this.renderRoleIcons()}
    </div>
  );

  private readonly renderTitle = () => {
    const { title } = this.props.member;
    return title && <p className={styles.title}>{title}</p>;
  };

  private readonly renderMember = () => (
    <div className={styles.member}>
      {this.renderAvatar()}
      {this.renderMemberDetails()}
    </div>
  );

  private readonly renderMemberDetails = () => (
    <div className={styles.memberDetails}>
      {this.renderNameAndRoleIcons()}
      {this.props.computed.showTitle && this.renderTitle()}
      {this.shouldRenderFF() && this.renderFollowers()}
      {this.renderBadges()}
      {this.props.computed.showAsBlocked && (
        <Blocked className={styles.blocked} t={this.props.t} />
      )}
    </div>
  );

  private readonly renderAvatar = () => {
    const { member, site, computed } = this.props;
    const { isSocial } = site;
    const { pictureStyle } = computed;

    return (
      <div className={styles.avatarButtonContainer}>
        <div className={styles.mainContent}>
          <Avatar
            dataHook={DataHook.ProfilePhoto}
            className={styles.profilePhoto}
            name={member.name}
            url={member.picture}
            layoutType={pictureStyle}
          />
        </div>
        {isSocial && this.renderButtons()}
      </div>
    );
  };

  private readonly renderLoader = () => (
    <LoaderOverlay
      dataHook={DataHook.Loader}
      className={styles.loader}
      size="small"
    />
  );

  private readonly renderCover = () => {
    const { profilePage, site, computed, handlers, t } = this.props;
    const { toggleIsEditingProfile } = handlers;
    const handleEditButtonClick = absorbArguments(toggleIsEditingProfile);

    return (
      <div data-hook={DataHook.CoverWrapper} className={styles.coverWrapper}>
        {this.renderCoverImage()}
        {computed.canEdit && site.isSocial && !profilePage.isProfilePreview && (
          <div
            className={classNames(styles.coverInputContainer, styles.canEdit)}
            onClick={handleEditButtonClick}
          >
            <label
              htmlFor="coverPhotoInput"
              className={styles.coverInputIcon}
            />
            <button
              id="coverPhotoInput"
              className={styles.uploadInput}
              aria-label={t('profile-widget.change-aria-label-photo')}
            />
          </div>
        )}
      </div>
    );
  };

  private readonly renderCoverImage = () => {
    const { member, globalSettings, computed, handlers } = this.props;
    const { defaultProfileCoverUrl: defaultCoverUrl } = globalSettings;
    const { isProfilePreview } = this.props.profilePage;
    const { toggleIsEditingProfile } = handlers;

    const coverUrl = getCoverUrl({ member, defaultCoverUrl, isMobile: true });
    const coverPhotoStyle = getCoverImageStyle({ coverUrl, member });
    const shouldHandleImageClick = computed.canEdit && !isProfilePreview;

    const handleImageClick = shouldHandleImageClick
      ? absorbArguments(toggleIsEditingProfile)
      : undefined;

    return (
      <div
        data-hook={DataHook.HighQualityCover}
        className={classNames(
          styles.cover,
          !coverPhotoStyle && styles.coverColor,
        )}
        style={coverPhotoStyle || {}}
        onClick={handleImageClick}
      >
        <div
          className={classNames(
            styles.coverOverlay,
            !coverPhotoStyle && styles.withoutPhoto,
          )}
        />
      </div>
    );
  };
}

export default MobileViewWidget;
