import React, { FC } from 'react';

import {
  BaseComponentProps,
  Experiments,
  ProfileLayout,
} from '../../../../types';
import DesktopResponsiveWidget from './DesktopResponsiveWidget';
import DesktopNonResponsiveWidget from './DesktopNonResponsiveWidget';
import DesktopNonResponsivePhotoWidget from './DesktopNonResponsivePhotoWidget';

const DesktopWidget: FC<BaseComponentProps> = (props) => {
  const { computed } = props;
  return props.computed.profileLayout === ProfileLayout.FullWidth ? (
    <DesktopResponsiveWidget {...props} />
  ) : computed.isCustomProfileEnabled ? (
    <DesktopNonResponsivePhotoWidget {...props} />
  ) : (
    <DesktopNonResponsiveWidget {...props} />
  );
};

export default DesktopWidget;
